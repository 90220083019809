var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.information
    ? _c("section", [
        _c("div", { staticClass: "mt-2" }, [
          _c("dl", [
            _c("dt", [_vm._v(_vm._s(_vm.$t("NAME")))]),
            _c("dd", [_vm._v(_vm._s(_vm.information.functionbox.name))]),
            _c("dt", [_vm._v(_vm._s(_vm.$t("EMAIL")))]),
            _c("dd", [_vm._v(_vm._s(_vm.information.functionbox.email))]),
            _c("dt", [_vm._v(_vm._s(_vm.$t("CREATED")))]),
            _c("dd", [
              _vm._v(
                _vm._s(
                  _vm.FormatDateTime(_vm.information.functionbox.created_date)
                )
              ),
            ]),
            _c("dt", [_vm._v(_vm._s(_vm.$t("UUID")))]),
            _c("dd", [
              _vm._v(_vm._s(_vm.information.functionbox.functionbox_uuid)),
            ]),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }