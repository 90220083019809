<template>
  <section v-if="information">
    <div class="mt-2">

      <dl>
        <dt>{{ $t("NAME") }}</dt>
        <dd>{{ information.functionbox.name }}</dd>
        <dt>{{ $t("EMAIL") }}</dt>
        <dd>{{ information.functionbox.email }}</dd>     
        <dt>{{ $t('CREATED') }}</dt>
        <dd>{{ FormatDateTime(information.functionbox.created_date) }}</dd>
        <dt>{{ $t('UUID') }}</dt>
        <dd>{{ information.functionbox.functionbox_uuid }}</dd>     
      </dl>

    </div>

  </section>
</template>
<script>
export default {
  props: ["information"],
  data() {
    return {   
    };
  },
  methods: {    
  },
  mounted: function() {
  }
};
</script>
<style>
</style>