var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.information
    ? _c("section", [
        _c(
          "div",
          {
            key: _vm.information.functionbox.functionbox_uuid,
            staticClass: "mt-2",
          },
          [
            _c("h3", [
              _vm._v("\n      " + _vm._s(_vm.information.functionbox.email)),
              _c("br"),
              _c("small", [_vm._v(_vm._s(_vm.information.functionbox.name))]),
            ]),
            _vm.information.functionbox.verified == 0
              ? _c("b-alert", { attrs: { show: "", variant: "warning" } }, [
                  _vm._v(_vm._s(_vm.$t("NOT_VERIFIED"))),
                ])
              : _vm._e(),
            _c(
              "b-nav",
              { staticClass: "hidden-mobile", attrs: { tabs: "" } },
              [
                _c(
                  "b-nav-item",
                  {
                    attrs: { active: _vm.tab == 0 },
                    on: {
                      click: function ($event) {
                        _vm.tab = 0
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("INFORMATION")))]
                ),
                _vm.information.functionbox.verified == 1
                  ? _c(
                      "b-nav-item",
                      {
                        attrs: { active: _vm.tab == 1 },
                        on: {
                          click: function ($event) {
                            _vm.tab = 1
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("ACCOUNTS")))]
                    )
                  : _vm._e(),
                !_vm.editUsers
                  ? _c(
                      "b-nav-item",
                      {
                        attrs: { active: _vm.tab == 2 },
                        on: {
                          click: function ($event) {
                            _vm.tab = 2
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("SETTINGS")))]
                    )
                  : _vm._e(),
                _vm.information.functionbox.verified == 1
                  ? _c(
                      "b-nav-item",
                      {
                        attrs: { active: _vm.tab == 3 },
                        on: {
                          click: function ($event) {
                            _vm.tab = 3
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("API")))]
                    )
                  : _vm._e(),
              ],
              1
            ),
            _c(
              "b-dropdown",
              {
                staticClass: "hidden-not-mobile",
                scopedSlots: _vm._u(
                  [
                    {
                      key: "button-content",
                      fn: function () {
                        return [
                          _vm.tab == 0
                            ? _c("span", [
                                _vm._v(_vm._s(_vm.$t("INFORMATION"))),
                              ])
                            : _vm._e(),
                          _vm.tab == 1
                            ? _c("span", [_vm._v(_vm._s(_vm.$t("ACCOUNTS")))])
                            : _vm._e(),
                          _vm.tab == 2
                            ? _c("span", [_vm._v(_vm._s(_vm.$t("SETTINGS")))])
                            : _vm._e(),
                          _vm.tab == 3
                            ? _c("span", [_vm._v(_vm._s(_vm.$t("API")))])
                            : _vm._e(),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  3918420552
                ),
              },
              [
                _c(
                  "b-dropdown-item",
                  {
                    attrs: { active: _vm.tab == 0 },
                    on: {
                      click: function ($event) {
                        _vm.tab = 0
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("INFORMATION")))]
                ),
                _vm.information.functionbox.verified == 1
                  ? _c(
                      "b-dropdown-item",
                      {
                        attrs: { active: _vm.tab == 1 },
                        on: {
                          click: function ($event) {
                            _vm.tab = 1
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("ACCOUNTS")))]
                    )
                  : _vm._e(),
                !_vm.editUsers
                  ? _c(
                      "b-dropdown-item",
                      {
                        attrs: { active: _vm.tab == 2 },
                        on: {
                          click: function ($event) {
                            _vm.tab = 2
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("SETTINGS")))]
                    )
                  : _vm._e(),
                _c(
                  "b-dropdown-item",
                  {
                    attrs: { active: _vm.tab == 3 },
                    on: {
                      click: function ($event) {
                        _vm.tab = 3
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("API")))]
                ),
              ],
              1
            ),
            _vm.tab == 2
              ? _c(
                  "div",
                  [
                    _c(
                      "b-nav",
                      { staticClass: "hidden-mobile", attrs: { tabs: "" } },
                      [
                        _c(
                          "b-nav-item",
                          {
                            attrs: { active: _vm.setting == 0 },
                            on: {
                              click: function ($event) {
                                _vm.setting = 0
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("INFORMATION")))]
                        ),
                        _vm.information.functionbox.verified == 1
                          ? _c(
                              "b-nav-item",
                              {
                                attrs: { active: _vm.setting == 1 },
                                on: {
                                  click: function ($event) {
                                    _vm.setting = 1
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("EMAIL")))]
                            )
                          : _vm._e(),
                        _vm.showExternalSystems &&
                        !_vm.editUsers &&
                        _vm.information.functionbox.verified == 1
                          ? _c(
                              "b-nav-item",
                              {
                                attrs: { active: _vm.setting == 2 },
                                on: {
                                  click: function ($event) {
                                    _vm.setting = 2
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("EXTERNAL_SYSTEMS")))]
                            )
                          : _vm._e(),
                        _vm.information.functionbox.verified == 1
                          ? _c(
                              "b-nav-item",
                              {
                                attrs: { active: _vm.setting == 3 },
                                on: {
                                  click: function ($event) {
                                    _vm.setting = 3
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("ADDRESSBOOK.TITLE")))]
                            )
                          : _vm._e(),
                        _vm.information.functionbox.verified == 1
                          ? _c(
                              "b-nav-item",
                              {
                                attrs: { active: _vm.setting == 4 },
                                on: {
                                  click: function ($event) {
                                    _vm.setting = 4
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("ALL_MESSAGES")))]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm.tab == 2
                      ? _c(
                          "b-dropdown",
                          {
                            staticClass: "hidden-not-mobile",
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "button-content",
                                  fn: function () {
                                    return [
                                      _vm.setting == 0
                                        ? _c("span", [
                                            _vm._v(
                                              _vm._s(_vm.$t("INFORMATION"))
                                            ),
                                          ])
                                        : _vm._e(),
                                      _vm.setting == 1
                                        ? _c("span", [
                                            _vm._v(_vm._s(_vm.$t("EMAIL"))),
                                          ])
                                        : _vm._e(),
                                      _vm.setting == 2
                                        ? _c("span", [
                                            _vm._v(
                                              _vm._s(_vm.$t("EXTERNAL_SYSTEMS"))
                                            ),
                                          ])
                                        : _vm._e(),
                                      _vm.setting == 3
                                        ? _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t("ADDRESSBOOK.TITLE")
                                              )
                                            ),
                                          ])
                                        : _vm._e(),
                                      _vm.setting == 4
                                        ? _c("span", [
                                            _vm._v(
                                              _vm._s(_vm.$t("ALL_MESSAGES"))
                                            ),
                                          ])
                                        : _vm._e(),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              877104118
                            ),
                          },
                          [
                            _c(
                              "b-dropdown-item",
                              {
                                attrs: { active: _vm.setting == 0 },
                                on: {
                                  click: function ($event) {
                                    _vm.setting = 0
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("INFORMATION")))]
                            ),
                            _vm.information.functionbox.verified == 1
                              ? _c(
                                  "b-dropdown-item",
                                  {
                                    attrs: { active: _vm.setting == 1 },
                                    on: {
                                      click: function ($event) {
                                        _vm.setting = 1
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("EMAIL")))]
                                )
                              : _vm._e(),
                            _vm.showExternalSystems &&
                            !_vm.editUsers &&
                            _vm.information.functionbox.verified == 1
                              ? _c(
                                  "b-dropdown-item",
                                  {
                                    attrs: { active: _vm.setting == 2 },
                                    on: {
                                      click: function ($event) {
                                        _vm.setting = 2
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("EXTERNAL_SYSTEMS")))]
                                )
                              : _vm._e(),
                            _vm.information.functionbox.verified == 1
                              ? _c(
                                  "b-dropdown-item",
                                  {
                                    attrs: { active: _vm.setting == 3 },
                                    on: {
                                      click: function ($event) {
                                        _vm.setting = 3
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("ADDRESSBOOK.TITLE")))]
                                )
                              : _vm._e(),
                            _vm.information.functionbox.verified == 1
                              ? _c(
                                  "b-dropdown-item",
                                  {
                                    attrs: { active: _vm.setting == 4 },
                                    on: {
                                      click: function ($event) {
                                        _vm.setting = 4
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("ALL_MESSAGES")))]
                                )
                              : _vm._e(),
                          ],
                          1
                        )
                      : _vm._e(),
                    !_vm.editUsers && _vm.setting == 0
                      ? _c("Edit", {
                          attrs: { tabIndex: 0, information: _vm.information },
                          on: {
                            updatedInfo: _vm.updatedInfo,
                            removedUser: _vm.removedBox,
                          },
                        })
                      : _vm._e(),
                    !_vm.editUsers && _vm.setting == 1
                      ? _c("Edit", {
                          attrs: { tabIndex: 1, information: _vm.information },
                          on: {
                            updatedInfo: _vm.updatedInfo,
                            removedUser: _vm.removedBox,
                          },
                        })
                      : _vm._e(),
                    _vm.setting == 4
                      ? _c("GeneralSetting", {
                          attrs: {
                            organisation_uuid:
                              _vm.information.functionbox.organisation_uuid,
                            functionbox_uuid: _vm.functionbox_uuid,
                          },
                        })
                      : _vm._e(),
                    _vm.showExternalSystems &&
                    !_vm.editUsers &&
                    _vm.setting == 2
                      ? _c("ExternalSystems", {
                          attrs: { information: _vm.information },
                        })
                      : _vm._e(),
                    _vm.showAddressbook &&
                    !_vm.editUsers &&
                    _vm.information.functionbox.organisation_uuid &&
                    _vm.setting == 3
                      ? _c("SettingAddressBook", {
                          attrs: {
                            organisation_uuid:
                              _vm.information.functionbox.organisation_uuid,
                            functionbox_uuid: _vm.functionbox_uuid,
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
            _vm.tab == 0
              ? _c("Information", {
                  attrs: { information: _vm.information },
                  on: { updatedInfo: _vm.updatedInfo },
                })
              : _vm._e(),
            _vm.information.functionbox.verified == 1 && _vm.tab == 1
              ? _c("EditUsers", {
                  attrs: { information: _vm.information },
                  on: { updatedUsers: _vm.updatedUsers },
                })
              : _vm._e(),
            !_vm.editUsers && _vm.tab == 3
              ? _c("API", { attrs: { information: _vm.information } })
              : _vm._e(),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }