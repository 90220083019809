<template>
  <section class="mt-2">

    <b-overlay :show="loading" rounded="sm">
      
      <b-tabs content-class="mt-3">
        <b-tab :title="$t('SDK.TITLE')" active>
    
          <b-form-group label-cols-md="4" :label="$t('SDK.IDENTIFIER')">
            <b-form-input trim
              required
              type="text"
              v-model="ExternalFunctionBoxDTO.sdk_identifier"
              name="name"
            ></b-form-input>
          </b-form-group>
    
          <b-form-group label-cols-md="4" :label="$t('SDK.ENVIRONMENT.TITLE')">
            <b-form-select name="edit_functionbox_sdk_environment" v-model="ExternalFunctionBoxDTO.sdk_environment">
              <b-form-select-option value="QA">{{ $t('SDK.ENVIRONMENT.QA') }}</b-form-select-option>
              <b-form-select-option value="PRODUCTION">{{ $t('SDK.ENVIRONMENT.PRODUCTION') }}</b-form-select-option>
              <b-form-select-option value="OPEN_TEST">{{ $t('SDK.ENVIRONMENT.OPEN_TEST') }}</b-form-select-option>
            </b-form-select>
          </b-form-group>
                    
          <b-form-group label-cols-md="4" label="Webhook">
            <b-form-input trim
              required
              type="text"
              v-model="ExternalFunctionBoxDTO.sdk_webhook"
              name="sdk_webhook"
            ></b-form-input>
          </b-form-group>

        </b-tab>

        <b-tab :title="$t('FAX.TITLE')" active>

          <b-form-group label-cols-md="4" :label="$t('FAX.NUMBER')">
            <Phone v-model="ExternalFunctionBoxDTO.fax_number" required></Phone>
          </b-form-group>

          <b-form-group label-cols-md="4" :label="$t('FAX.CLIENT_IDENTIFIER')" v-if="showFaxEdit">
            <b-form-input trim
              required
              type="text"
              v-model="ExternalFunctionBoxDTO.fax_client_identifier"
              name="name"
            ></b-form-input>
          </b-form-group>
          
          <b-form-group label-cols-md="4" label="subscriber_id" v-if="showFaxEdit">
            <b-form-input trim
              required
              type="text"
              v-model="fax_sip_subscriber_id"
              name="name"
            ></b-form-input>
          </b-form-group>

          <b-form-group label-cols-md="4" :label="$t('API')" v-if="showFaxEdit">
            <b-form-input trim
              required
              type="text"
              v-model="ExternalFunctionBoxDTO.fax_api_key"
              name="name"
            ></b-form-input>
          </b-form-group>

        </b-tab>
      </b-tabs>
 
  


      <hr class="p-0 m-0 mt-2 mb-2" />

      <b-button variant="primary" class="btn-fill" @click="saveSubmit">{{
        $t("SAVE")
      }}</b-button>

      
    </b-overlay>
  </section>
</template>
<script>
import Phone from "@/components/Input/Phone.vue";
export default {
  components: { Phone },
  props: ["information"],
  data() {
    return {
      loading: false,
      fax_sip_subscriber_id: this.information.functionbox.fax_sip_subscriber_id + "",
      ExternalFunctionBoxDTO: {
        functionbox_uuid: this.information.functionbox.functionbox_uuid,
        sdk_identifier: this.information.functionbox.sdk_identifier,
        sdk_environment: this.information.functionbox.sdk_environment,
        sdk_webhook: this.information.functionbox.sdk_webhook,
        fax_number: this.information.functionbox.fax_number,
        fax_client_identifier: this.information.functionbox.fax_client_identifier,
        fax_api_key: this.information.functionbox.fax_api_key,
        fax_sip_subscriber_id: 0        
      }
    };
  },
  methods: {
    saveSubmit: function() {
      let self = this;
      this.loading = true;
      this.ExternalFunctionBoxDTO.fax_sip_subscriber_id = parseInt(this.fax_sip_subscriber_id);
      this.$http
        .post(
          this.user.hostname + "/administration/functionbox/update-external",
          this.ExternalFunctionBoxDTO
        )
        .then(function(result) {
          self.$noty.info(self.$t("SAVED"));
          self.$emit("updatedUser", result.data);
          self.loading = false;
        })
        .catch(function() {
          self.loading = false;
        });
    }
  },
  computed: {
    showFaxEdit(){
      return this.information.functionbox.email.includes("@sefax.se");
    },
  },
  mounted: function() {},
};
</script>
<style></style>
