var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "mt-2" },
    [
      _c(
        "b-overlay",
        { attrs: { show: _vm.loading, rounded: "sm" } },
        [
          _vm.tabIndex == 0
            ? _c(
                "div",
                [
                  _c(
                    "b-form-group",
                    { attrs: { "label-cols-md": "4", label: _vm.$t("NAME") } },
                    [
                      _c("b-form-input", {
                        attrs: {
                          trim: "",
                          required: "",
                          type: "text",
                          name: "name",
                        },
                        model: {
                          value: _vm.UpdateFunctionBoxDTO.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.UpdateFunctionBoxDTO, "name", $$v)
                          },
                          expression: "UpdateFunctionBoxDTO.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        "label-cols-md": "4",
                        label: _vm.$t("LANGUAGE.TITLE"),
                      },
                    },
                    [
                      _c(
                        "b-form-select",
                        {
                          attrs: { name: "edit_functionbox_language" },
                          model: {
                            value: _vm.UpdateFunctionBoxDTO.locale,
                            callback: function ($$v) {
                              _vm.$set(_vm.UpdateFunctionBoxDTO, "locale", $$v)
                            },
                            expression: "UpdateFunctionBoxDTO.locale",
                          },
                        },
                        _vm._l(_vm.sefos_locales, function (value, key) {
                          return _c(
                            "b-form-select-option",
                            { key: key, attrs: { value: key } },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.$t("LANGUAGE." + key)) +
                                  "\n            "
                              ),
                            ]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        "label-cols-md": "4",
                        label: _vm.$t("SIGNATURE"),
                      },
                    },
                    [
                      _c("b-form-textarea", {
                        attrs: { rows: "5", "max-rows": "6" },
                        model: {
                          value: _vm.UpdateFunctionBoxDTO.signature,
                          callback: function ($$v) {
                            _vm.$set(_vm.UpdateFunctionBoxDTO, "signature", $$v)
                          },
                          expression: "UpdateFunctionBoxDTO.signature",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.tabIndex == 1
            ? _c(
                "div",
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        "label-cols-md": "4",
                        label: _vm.$t("MAIL_SERVER"),
                      },
                    },
                    [
                      _c("b-form-select", {
                        attrs: { options: _vm.providerOptions },
                        model: {
                          value: _vm.UpdateFunctionBoxDTO.email_provider,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.UpdateFunctionBoxDTO,
                              "email_provider",
                              $$v
                            )
                          },
                          expression: "UpdateFunctionBoxDTO.email_provider",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        "label-cols-md": "4",
                        label: _vm.$t("EMAILSETTINGS.FROM"),
                      },
                    },
                    [
                      _vm.UpdateFunctionBoxDTO.email_provider == "ORGANISATION"
                        ? _c(
                            "b-form-radio",
                            {
                              attrs: { value: "0" },
                              model: {
                                value:
                                  _vm.UpdateFunctionBoxDTO
                                    .email_force_from_email,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.UpdateFunctionBoxDTO,
                                    "email_force_from_email",
                                    $$v
                                  )
                                },
                                expression:
                                  "UpdateFunctionBoxDTO.email_force_from_email",
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.information.organisation_email_from)
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm.UpdateFunctionBoxDTO.email_provider == "ORGANISATION"
                        ? _c(
                            "b-form-radio",
                            {
                              staticClass: "mt-2",
                              attrs: { value: "1" },
                              model: {
                                value:
                                  _vm.UpdateFunctionBoxDTO
                                    .email_force_from_email,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.UpdateFunctionBoxDTO,
                                    "email_force_from_email",
                                    $$v
                                  )
                                },
                                expression:
                                  "UpdateFunctionBoxDTO.email_force_from_email",
                              },
                            },
                            [_vm._v(_vm._s(_vm.information.functionbox.email))]
                          )
                        : _vm._e(),
                      _vm.UpdateFunctionBoxDTO.email_provider != "ORGANISATION"
                        ? _c("b-form-input", {
                            attrs: {
                              trim: "",
                              plaintext: "",
                              required: "",
                              type: "text",
                              name: "email",
                            },
                            model: {
                              value: _vm.information.functionbox.email,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.information.functionbox,
                                  "email",
                                  $$v
                                )
                              },
                              expression: "information.functionbox.email",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm.UpdateFunctionBoxDTO.email_provider != "SEFOS" &&
                  _vm.UpdateFunctionBoxDTO.email_provider != "ORGANISATION"
                    ? _c(
                        "div",
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                "label-cols-md": "4",
                                label: _vm.$t("EMAILSETTINGS.PROTOCOL_TITLE"),
                              },
                            },
                            [
                              _c("b-form-select", {
                                attrs: {
                                  name: "edit_functionbox_email_mode",
                                  options: _vm.email_mode,
                                },
                                model: {
                                  value: _vm.UpdateFunctionBoxDTO.email_mode,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.UpdateFunctionBoxDTO,
                                      "email_mode",
                                      $$v
                                    )
                                  },
                                  expression: "UpdateFunctionBoxDTO.email_mode",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.UpdateFunctionBoxDTO.email_mode ==
                                    "SMTP",
                                  expression:
                                    "UpdateFunctionBoxDTO.email_mode == 'SMTP'",
                                },
                              ],
                            },
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    "label-cols-md": "4",
                                    label: _vm.$t("EMAILSETTINGS.HOSTNAME"),
                                  },
                                },
                                [
                                  _c("b-form-input", {
                                    attrs: {
                                      trim: "",
                                      required: "",
                                      type: "text",
                                      name: "email",
                                    },
                                    model: {
                                      value:
                                        _vm.UpdateFunctionBoxDTO.email_hostname,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.UpdateFunctionBoxDTO,
                                          "email_hostname",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "UpdateFunctionBoxDTO.email_hostname",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    "label-cols-md": "4",
                                    label: _vm.$t("EMAILSETTINGS.PORT"),
                                  },
                                },
                                [
                                  _c("b-form-input", {
                                    attrs: {
                                      trim: "",
                                      required: "",
                                      type: "number",
                                      name: "email",
                                    },
                                    model: {
                                      value:
                                        _vm.UpdateFunctionBoxDTO.email_port,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.UpdateFunctionBoxDTO,
                                          "email_port",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "UpdateFunctionBoxDTO.email_port",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    "label-cols-md": "4",
                                    label: _vm.$t("EMAILSETTINGS.USERNAME"),
                                  },
                                },
                                [
                                  _c("b-form-input", {
                                    attrs: {
                                      trim: "",
                                      required: "",
                                      type: "text",
                                      name: "email",
                                    },
                                    model: {
                                      value:
                                        _vm.UpdateFunctionBoxDTO.email_username,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.UpdateFunctionBoxDTO,
                                          "email_username",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "UpdateFunctionBoxDTO.email_username",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    "label-cols-md": "4",
                                    label: _vm.$t("EMAILSETTINGS.PASSWORD"),
                                  },
                                },
                                [
                                  _c("b-form-input", {
                                    attrs: {
                                      trim: "",
                                      required: "",
                                      type: "password",
                                      name: "email",
                                    },
                                    model: {
                                      value:
                                        _vm.UpdateFunctionBoxDTO.email_password,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.UpdateFunctionBoxDTO,
                                          "email_password",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "UpdateFunctionBoxDTO.email_password",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    "label-cols-md": "4",
                                    label: _vm.$t(
                                      "EMAILSETTINGS.SECURITY.TITLE"
                                    ),
                                  },
                                },
                                [
                                  _c("b-form-select", {
                                    attrs: { options: _vm.email_securitys },
                                    model: {
                                      value:
                                        _vm.UpdateFunctionBoxDTO.email_security,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.UpdateFunctionBoxDTO,
                                          "email_security",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "UpdateFunctionBoxDTO.email_security",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.UpdateFunctionBoxDTO.email_mode ==
                                    "GRAPH",
                                  expression:
                                    "UpdateFunctionBoxDTO.email_mode == 'GRAPH'",
                                },
                              ],
                            },
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    "label-cols-md": "4",
                                    label: _vm.$t("EMAILSETTINGS.TENANT"),
                                  },
                                },
                                [
                                  _c("b-form-input", {
                                    attrs: {
                                      trim: "",
                                      type: "text",
                                      name: "name",
                                    },
                                    model: {
                                      value:
                                        _vm.UpdateFunctionBoxDTO
                                          .email_graph_tenant,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.UpdateFunctionBoxDTO,
                                          "email_graph_tenant",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "UpdateFunctionBoxDTO.email_graph_tenant",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    "label-cols-md": "4",
                                    label: _vm.$t("EMAILSETTINGS.CLIENTID"),
                                  },
                                },
                                [
                                  _c("b-form-input", {
                                    attrs: {
                                      trim: "",
                                      type: "text",
                                      name: "name",
                                    },
                                    model: {
                                      value:
                                        _vm.UpdateFunctionBoxDTO
                                          .email_graph_client_id,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.UpdateFunctionBoxDTO,
                                          "email_graph_client_id",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "UpdateFunctionBoxDTO.email_graph_client_id",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    "label-cols-md": "4",
                                    label: _vm.$t("EMAILSETTINGS.CLIENTSECRET"),
                                  },
                                },
                                [
                                  _c("b-form-input", {
                                    attrs: {
                                      trim: "",
                                      type: "password",
                                      name: "name",
                                    },
                                    model: {
                                      value:
                                        _vm.UpdateFunctionBoxDTO
                                          .email_graph_client_secret,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.UpdateFunctionBoxDTO,
                                          "email_graph_client_secret",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "UpdateFunctionBoxDTO.email_graph_client_secret",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                "label-cols-md": "4",
                                label: _vm.$t("EMAILSETTINGS.REPLY_TO"),
                              },
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  trim: "",
                                  required: "",
                                  type: "text",
                                  name: "email",
                                },
                                model: {
                                  value: _vm.UpdateFunctionBoxDTO.email_replyto,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.UpdateFunctionBoxDTO,
                                      "email_replyto",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "UpdateFunctionBoxDTO.email_replyto",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        "label-cols-md": "4",
                        label: _vm.$t(
                          "EMAILSETTINGS.NOTIFICATIONS.DISABLED_LABEL"
                        ),
                      },
                    },
                    [
                      _c(
                        "b-form-checkbox",
                        {
                          attrs: { name: "email_notification_disabled" },
                          model: {
                            value:
                              _vm.UpdateFunctionBoxDTO
                                .email_notification_disabled,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.UpdateFunctionBoxDTO,
                                "email_notification_disabled",
                                $$v
                              )
                            },
                            expression:
                              "UpdateFunctionBoxDTO.email_notification_disabled",
                          },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.$t(
                                  "EMAILSETTINGS.NOTIFICATIONS.DISABLED_LABEL"
                                )
                              ) +
                              "\n          "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c("hr", { staticClass: "p-0 m-0 mt-2 mb-2" }),
          _c(
            "b-button",
            {
              staticClass: "btn-fill",
              attrs: { variant: "primary" },
              on: { click: _vm.saveSubmit },
            },
            [_vm._v(_vm._s(_vm.$t("SAVE")))]
          ),
          _vm.tabIndex == 1
            ? _c(
                "b-button",
                {
                  staticClass: "btn-fill",
                  attrs: { variant: "secondary", disabled: _vm.testDisabled },
                  on: { click: _vm.testBtn },
                },
                [_vm._v(_vm._s(_vm.$t("TEST")))]
              )
            : _vm._e(),
          _vm.tabIndex == 0
            ? _c(
                "b-button",
                {
                  staticClass: "btn-fill float-right",
                  attrs: { variant: "danger" },
                  on: {
                    click: function ($event) {
                      _vm.showRemoveFunctionbox = true
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("REMOVE")))]
              )
            : _vm._e(),
          _c(
            "b-modal",
            {
              attrs: {
                "hide-header": "",
                "ok-disabled": _vm.okRemoveDisabled,
                centered: "",
                "ok-variant": "danger",
                "cancel-title": _vm.$t("CANCEL"),
                "ok-title": _vm.$t("REMOVE"),
              },
              on: { cancel: _vm.cancelRemoveFunctionbox, ok: _vm.removeSubmit },
              model: {
                value: _vm.showRemoveFunctionbox,
                callback: function ($$v) {
                  _vm.showRemoveFunctionbox = $$v
                },
                expression: "showRemoveFunctionbox",
              },
            },
            [
              _c("div", {
                domProps: {
                  innerHTML: _vm._s(
                    _vm.$t("CONFIRM.DO_YOU_WANT_TO_REMOVE_FUNCTIONBOX")
                  ),
                },
              }),
              _c("hr", { staticClass: "p-0 m-0 mt-4 mb-2" }),
              _c(
                "b-form-group",
                {
                  attrs: {
                    id: "input-1",
                    label: _vm.$t("REMOVE_FUNCTIONBOX_NAME"),
                    "label-for": "input-2",
                  },
                },
                [
                  _c("b-alert", { attrs: { show: "", variant: "info" } }, [
                    _vm._v(_vm._s(_vm.information.functionbox.name)),
                  ]),
                  _c("b-form-input", {
                    attrs: {
                      trim: "",
                      "aria-describedby": "label-help-block",
                      placeholder: "",
                    },
                    model: {
                      value: _vm.remove_functionbox_name,
                      callback: function ($$v) {
                        _vm.remove_functionbox_name = $$v
                      },
                      expression: "remove_functionbox_name",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }